<template>
  <div>
    专题
  </div>
</template>

<script>
export default {
  data () {
    return {

 
    }
  }
}
</script>
 
<style lang = "less" scoped>
  
</style>